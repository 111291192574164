<template>
  <form @submit.prevent="onSubmit()">
    <div class="view view--user">

      <div class="view-header" v-if="pageTitle">
        <div class="view-header__footer">
          <Btn class="back-btn" :to="{ name: 'myAccount' }" round grow icon="arrow-left" />
          <h1 class="page-title" v-html="pageTitle"></h1>
        </div>
      </div>

      <div class="view-body">
        <Section class="section">
          <Container>

            <div class="grid">
              <div class="col-tmp">
                <Input
                  id="motdepasse"
                  label="Mot de passe actuel"
                  type="password"
                  required
                />
              </div>
              <div class="col-tmp">
                <Input
                  id="nouveau_motdepasse"
                  label="Nouveau mot de passe"
                  type="password"
                  required
                />
                <Input
                  id="nouveau_motdepasse_confirmation"
                  label="Confirmer le nouveau mot de passe"
                  type="password"
                  required
                />
              </div>
            </div>

          </Container>
        </Section>
      </div>

      <div class="view-footer">
        <div class="view-actions view-actions--left">
          <Btn :to="{ name: 'myAccount' }" text="Annuler" />
          <Btn btnType="submit"  text="Modifier le mot de passe" color="primary" />
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import Input from '@/components/form/Input.vue'
import Btn from '@/components/base/Btn.vue'
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'

import * as yup from 'yup'

export default {
  name: 'MyAccountEditPasswordView',

  components: {
    Input,
    Btn,
    Section,
    Container,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    // Define a validation schema
    const validationSchema = yup.object().shape({
      motdepasse: yup.string().required(),
      nouveau_motdepasse: yup.string().required(),
      nouveau_motdepasse_confirmation: yup.string().required(),
    })

    const { errors, isSubmitting } = this.formService.initFrom(
      validationSchema,
    )
    const onSubmit = this.formService.handleSubmit((values) => {
      this.sendRequest(values)
    })

    return {
      data: [],
      onSubmit,
      errors,
      isSubmitting,
    }
  },
  watch: {
    errors() {
      this.formService.handleErrors(this.isSubmitting)
    },
  },
  methods: {
    sendRequest(values) {
      return this.fetchService
        .put('authentification/profil/mdp', values)
        .then(
          (response) => {
            this.formService.resetForm()
            this.emitter.emit('alert', {
              type: 'success',
              content: response.data,
            })
          },
          (responseError) => {
            this.formService.handleApiError(responseError.data)
            this.emitter.emit('alert', {
              type: 'error',
              content: 'Oups',
            })
          },
        )
    },
  },
}
</script>
